<template>
  <div class="inv__list">
    <div class="inv__item">
      <!--      <h2 class="inv__item__title">chart title</h2>-->
      <div class="inv__item__chart">
        <ClientOnly>
          <VueApexCharts
            width="100%"
            height="370"
            type="donut"
            :options="pieOptions"
            :series="pieSeries"
          ></VueApexCharts>
        </ClientOnly>
      </div>
    </div>
    <div class="inv__item">
      <div class="inv__item__chart">
        <ClientOnly>
          <VueApexCharts
            width="100%"
            height="370"
            type="line"
            :options="lineOptions"
            :series="lineSeries"
          ></VueApexCharts>
        </ClientOnly>
      </div>
    </div>
  </div>
</template>

<script>
import ClientOnly from "vue-client-only";

export default {
  name: "ChartsList",
  data() {
    return {
      pieSeries: [59, 27, 5, 3, 6],
      pieOptions: {
        labels: [
          "Расходы на персонал",
          "Выплата по фин. деятельности",
          "Хозяйственные расходы",
          "Административные расходы",
          "Налоги",
        ],
        plotOptions: {
          pie: {
            donut: {
              size: "30%",
            },
          },
        },
        responsive: [
          {
            breakpoint: 768,
            options: {
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      lineOptions: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: "smooth",
        },
        markers: {
          size: 5,
        },
        xaxis: {
          categories: ["Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь"],
        },
        responsive: [
          {
            breakpoint: 640,
            options: {
              chart: {
                height: 300,
              },
            },
          },
        ],
      },
      lineSeries: [
        {
          name: "Расходы на персонал",
          data: [30, 40, 45, 50, 49, 60],
        },
        {
          name: "Выплата по фин. деятельности",
          data: [20, 50, 20, 30, 49, 69],
        },
      ],
    };
  },
  components: {
    ClientOnly,
    VueApexCharts: () => import("vue-apexcharts"),
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/inv.styl"

.apexcharts-legend {
  +below(420px) {
    flex-direction column
  }
}

.chart-17
  .apexcharts-legend
    overflow initial !important

  .apexcharts-legend-series
    padding 5px

    &:hover
      .pie__data
        margin-bottom 3px
        opacity 1
        pointer-events auto

.pie__data
  absolute left 50% bottom 100%
  padding 5px 10px
  font-size 1em
  transform translate(-50%, 0)
  color @css {rgb(var(--main_white))}
  box-shadow @css {rgba(var(--main_dark), 0.15)} 0 2px 3px
  border-radius 5px
  margin-bottom 0
  opacity 0
  pointer-events none
  transition margin-bottom 0.3s
</style>
