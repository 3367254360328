<template>
  <main class="main">
    <div class="container">
      <div class="main__inner main__inner">
        <section v-if="receptionPage">
          <div class="title__wrap">
            <h3>Прием корреспонденции и граждан</h3>
          </div>
          <BreadcrumbsComponent title="Прием корреспонденции и граждан" />
          <section v-if="receptionPage.description" class="section section__divider">
            <EditorJSComponent
              :text="JSON.parse(receptionPage.description)"
              v-if="JSON.parse(receptionPage.description)"
            />
          </section>
<!--          <section class="section">-->
<!--            <h3>Статистика приема корреспонденции и граждан</h3>-->
<!--            <ChartsList />-->
<!--          </section>-->
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";
import ChartsList from "@/views/feedback/components/ChartsList.vue";

export default {
  name: "FeedbackReceptionPage",
  async asyncData({ store }) {
    await store.dispatch("GET_RECEPTION_PAGE");
  },
  computed: {
    receptionPage() {
      return this.$store.state.reception_page;
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.state.reception_page = {};
    next();
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
  jsonld() {
    return {
      "@context": "https://schema.org",
      "@type": "Organization",
    };
  },
  components: {
    BreadcrumbsComponent,
    EditorJSComponent,
    ChartsList,
  },
};
</script>

<style lang="stylus">

. {
  padding 32px 32px 24px
}
</style>
